// IconBurgerMenu.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconBurgerMenu.module.css';

const IconBurgerMenu = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.55671e-08" y1="1.25" x2="14" y2="1.25" stroke="#112148" stroke-width="1.5"/>
      <line x1="6.55671e-08" y1="5.25" x2="14" y2="5.25" stroke="#112148" stroke-width="1.5"/>
      <line x1="6.55671e-08" y1="9.25" x2="14" y2="9.25" stroke="#112148" stroke-width="1.5"/>
    </svg>
  );
};

IconBurgerMenu.defaultProps = { className: null };

IconBurgerMenu.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBurgerMenu; // Ensure this is a default export
