// IconBath.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconBath.module.css';

const IconBath = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="47" height="37" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.1982 17.0338C24.1982 19.443 22.2694 21.3877 19.88 21.3877C19.097 21.3877 18.36 21.1787 17.7209 20.8014C17.0876 21.1729 16.3506 21.3877 15.5561 21.3877C13.967 21.3877 12.5851 20.5285 11.8424 19.2514C11.0939 20.5285 9.71209 21.3877 8.12875 21.3877C5.73934 21.3877 3.81055 19.443 3.81055 17.0338C3.81055 14.6247 5.73934 12.68 8.12875 12.68C8.54329 12.68 8.95784 12.7438 9.33784 12.8599C9.86754 11.0545 11.5142 9.74835 13.4718 9.74835C15.5676 9.74835 17.3179 11.2635 17.7094 13.2721H17.7152C18.3542 12.8947 19.0855 12.6858 19.8742 12.6858C22.2637 12.6858 24.1924 14.6363 24.1924 17.0396L24.1982 17.0338Z" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M45.7653 18.1832H1V23.4136H45.7653V18.1832Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M36.5296 33.8805H9.71067C8.24248 33.8805 6.91248 33.0213 6.29641 31.6745L2.51367 23.4138H44.1123L39.8805 31.808C39.2414 33.0793 37.9402 33.8805 36.5296 33.8805Z" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M41.0776 6.59617C41.1467 7.43211 41.1006 17.3415 41.1006 18.1775H38.193C38.193 17.4112 38.193 7.58305 38.193 6.81677C38.1988 5.17391 36.6039 3.89677 35.0551 3.89677C33.4603 3.89677 32.1533 5.06361 31.9057 6.59617H29.0039C29.2688 3.46139 31.8769 1 35.0551 1C36.7076 1 38.3139 1.69662 39.4539 2.90409C40.0009 3.48461 40.4385 4.17542 40.7206 4.92428C40.9279 5.46997 41.0315 6.03307 41.0776 6.60197V6.59617Z" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M27.5833 14.0558C28.7631 14.0558 29.7194 13.0915 29.7194 11.9021C29.7194 10.7126 28.7631 9.74835 27.5833 9.74835C26.4036 9.74835 25.4473 10.7126 25.4473 11.9021C25.4473 13.0915 26.4036 14.0558 27.5833 14.0558Z" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M37.3145 33.8811V36.5515" stroke="#112148" stroke-miterlimit="10"/>
      <path d="M9.45898 33.8809V36.5512" stroke="#112148" stroke-miterlimit="10"/>
    </svg>
  );
};

IconBath.defaultProps = { className: null };

IconBath.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBath; // Ensure this is a default export
