// IconEyeOn.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEyeOn.module.css';

const IconEyeOn = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00012 3.00317C7.70737 3.00317 8.38564 3.28412 8.88574 3.78422C9.38584 4.28432 9.66679 4.96259 9.66679 5.66984C9.66679 6.37708 9.38584 7.05536 8.88574 7.55546C8.38564 8.05555 7.70737 8.3365 7.00012 8.3365C6.29288 8.3365 5.6146 8.05555 5.11451 7.55546C4.61441 7.05536 4.33346 6.37708 4.33346 5.66984C4.33346 4.96259 4.61441 4.28432 5.11451 3.78422C5.6146 3.28412 6.29288 3.00317 7.00012 3.00317ZM7.00012 4.00317C6.5581 4.00317 6.13417 4.17877 5.82161 4.49133C5.50905 4.80389 5.33346 5.22781 5.33346 5.66984C5.33346 6.11187 5.50905 6.53579 5.82161 6.84835C6.13417 7.16091 6.5581 7.3365 7.00012 7.3365C7.44215 7.3365 7.86607 7.16091 8.17863 6.84835C8.4912 6.53579 8.66679 6.11187 8.66679 5.66984C8.66679 5.22781 8.4912 4.80389 8.17863 4.49133C7.86607 4.17877 7.44215 4.00317 7.00012 4.00317ZM7.00012 0.666504C10.0755 0.666504 12.7308 2.7665 13.4675 5.70917C13.4997 5.8378 13.4796 5.97398 13.4114 6.08775C13.3433 6.20153 13.2328 6.28357 13.1041 6.31584C12.9755 6.34811 12.8393 6.32795 12.7255 6.25982C12.6118 6.19168 12.5297 6.08113 12.4975 5.9525C12.1888 4.72815 11.4802 3.64188 10.4841 2.86593C9.48804 2.08997 8.26141 1.66876 6.99875 1.66906C5.73609 1.66937 4.50967 2.09118 3.51394 2.86762C2.51822 3.64405 1.81019 4.73067 1.50212 5.95517C1.48623 6.01891 1.45795 6.07889 1.41888 6.13169C1.37981 6.18449 1.33072 6.22908 1.27441 6.26291C1.21811 6.29674 1.15569 6.31916 1.09073 6.32887C1.02577 6.33858 0.959526 6.33539 0.895791 6.3195C0.832056 6.30361 0.772075 6.27533 0.719272 6.23626C0.66647 6.19719 0.62188 6.1481 0.588049 6.09179C0.554217 6.03549 0.531807 5.97308 0.522097 5.90811C0.512387 5.84315 0.515568 5.77691 0.531458 5.71317C0.892685 4.27119 1.72549 2.99131 2.89753 2.07692C4.06957 1.16253 5.51359 0.666097 7.00012 0.666504Z" fill="#112148"/>
    </svg>
  );
};

IconEyeOn.defaultProps = { className: null };

IconEyeOn.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEyeOn; // Ensure this is a default export
