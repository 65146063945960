// IconBed.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconBed.module.css';

const IconBed = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="48" height="25" viewBox="0 0 48 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_132_2170)">
        <path d="M12.2008 1.48392H35.7989C37.884 1.48392 39.5727 3.16021 39.5727 5.22995V9.05683H8.427V5.22995C8.427 3.16021 10.1157 1.48392 12.2008 1.48392Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
        <path d="M8.46456 9.12177H39.5342C42.2492 9.12177 44.4483 11.3101 44.4483 13.9997V14.9645H3.55054V13.9997C3.55054 11.3047 5.75506 9.12177 8.46456 9.12177Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
        <path d="M45.9862 14.9698H2.01519C1.20251 14.9698 0.543701 15.6238 0.543701 16.4305V20.6616C0.543701 21.4683 1.20251 22.1223 2.01519 22.1223H45.9862C46.7989 22.1223 47.4577 21.4683 47.4577 20.6616V16.4305C47.4577 15.6238 46.7989 14.9698 45.9862 14.9698Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
        <path d="M21.6224 4.82027H13.3853C12.7106 4.82027 12.1636 5.36323 12.1636 6.03301V7.90872C12.1636 8.5785 12.7106 9.12146 13.3853 9.12146H21.6224C22.2971 9.12146 22.8441 8.5785 22.8441 7.90872V6.03301C22.8441 5.36323 22.2971 4.82027 21.6224 4.82027Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
        <path d="M34.6916 4.82029H26.3024C25.6697 4.82029 25.1567 5.32947 25.1567 5.95757V7.9842C25.1567 8.61231 25.6697 9.12149 26.3024 9.12149H34.6916C35.3243 9.12149 35.8373 8.61231 35.8373 7.9842V5.95757C35.8373 5.32947 35.3243 4.82029 34.6916 4.82029Z" fill="white" stroke="#112148" stroke-miterlimit="10"/>
        <path d="M45.2297 22.1224H44.1926V24.6072H45.2297V22.1224Z" fill="#112148"/>
        <path d="M3.55078 22.1224H2.51367V24.6072H3.55078V22.1224Z" fill="#112148"/>
      </g>
      <defs>
      <clipPath id="clip0_132_2170">
      <rect width="48" height="23.662" fill="white" transform="translate(0 0.944748)"/>
      </clipPath>
</defs>
    </svg>
  );
};

IconBed.defaultProps = { className: null };

IconBed.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBed; // Ensure this is a default export
